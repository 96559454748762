<template>
	<div class="container">
		<div  :class="['nav-container', valuechange == 1 ? 'active' : '']">
			<div>
				<img src="@/assets/image/logo.png" class="logoimg" @click="logoClick">
			</div>
			<div class="nav" @mouseleave="handleNavLeave">
				<div v-for="(item,index) in navlist" :key="index"
					:class="currentNavIndex == index ? 'nav-item-current' : 'nav-item'" @click="itemClick(index)"
					@mouseover="handleMouseOver(index)">
					{{item}}
				</div>
			</div>
			<div>
				<span class="iconfont icon-menu" style="color: #FFFFFF;" @click="menuClick"></span>
			</div>
		</div>

		<div class="popup" :class="flag ? 'active':''" @mouseleave="handleMouseLeave">
			<div v-for="(item,index) in popuplist" :key="index" class="item-box">
				<div class="img-box">
					<img :src="item.src" class="item-img" />
				</div>
				<div class="label"  @click="onchange(item)">
					<span class="label-val" >
						{{item.label}}
						<!-- <a style=" text-decoration:none" href="https://detail.tmall.com/item.htm?spm=a1z10.5-b-s.w4011-23975132646.33.3ada77f5R5enZU&id=654535365380&rn=4152c5abad659cc831120f74bc654941&abbucket=17" target="_blank">{{item.label}}</a> -->
					</span>
					<span class="iconfont icon-right arrow-right"></span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'customNav',
		props:{
			valuechange:''
		},
		data() {
			return {
				cahnge:1,
				navlist: ['S级小型种植机', 'M级中型种植机', 'L型大型种植机', '集装箱植物工厂', '植物工厂', '配件','方寸间智能栽培'],
				currentNavIndex: null,
				isIndex: false,
				popuplist: [
		
				{
					src: require('@/assets/image/popup-item02.png'),
					label: '营养液',
					page:'https://detail.tmall.com/item.htm?spm=a1z10.5-b-s.w4011-23975132646.33.3ada77f5R5enZU&id=654535365380&rn=4152c5abad659cc831120f74bc654941&abbucket=17'
				}, 
			
				],
				flag: false
			}
		},
		
		methods: {
			getClass(value) {
				this.$bus.$on('navClick', (value) => {
					this.isIndex = value
				})
			},
			// logo点击跳转到主页
			logoClick() {
				this.currentNavIndex = null;
				this.isIndex = true;
				this.$bus.$emit('navClick', this.isIndex);
				this.$router.push("/index");
			},
			// 导航栏点击
			itemClick(index) {
				if (this.currentNavIndex == 0) {
					this.$router.push('/goodS');
				} else if(this.currentNavIndex == 6){
					this.$router.push('/cultivation');
				}else {
					console.log('===========’');
				}
			},
			// 菜单点击
			menuClick() {
				// console.log('hahhaha')
				this.$bus.$emit('menuClick', true)
				this.flag = false
			},
			handleMouseOver(index) {
				this.currentNavIndex = index
				if (this.currentNavIndex == 5) {
					this.flag = true
				}else {
					this.flag = false
				}

			},
			handleMouseLeave() {
				this.flag = false
			},
			handleNavLeave() {
				this.currentNavIndex = null
			},
			// 选中商品的跳转
			onchange(value){
				window.location.href=value.page
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
	}

	.nav-container {
		background: rgba(1, 1, 1, .5);
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 15px 0;
	}
	.active{
		background-color: black;
	}

	.nav {
		display: flex;
	}

	.nav-item {
		padding: 12px 30px;
		font-size: 16px;
		color: #FFFFFF;
		margin-left: 10px;
	}

	.nav-item-current {
		padding: 12px 30px;
		background: #4AB17D;
		border-radius: 20px;
		font-size: 16px;
		color: #FFFFFF;
		margin-left: 10px;
	}

	.iconfont {
		font-size: 36px;
		color: #FFFFFF;
	}

	.popup {
		width: 100%;
		height: 200px;
		background-color: #fff;
		transition: all .8s ease-in-out;
		display: flex;
		justify-content: start;
		position: absolute;
		left: -10000px;
		z-index: 99999;
		// padding-bottom: 20px;
		padding: 20px 200px;
	}

	.active {
		left: 0;
		z-index: 99999;
	}

	.item-box {
		
		display: flex;
		flex-direction: column;

		&:hover {

			.label-val,
			.arrow-right {
				color: #4AB17D;
			}
		}
	}



	.img-box {
		flex: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.label {
		margin-top: 10px;
		flex: .3;
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.label-val {
		font-size: 16px;
		color: #111111;
	}

	.iconfont {
		font-size: 24px;
		color: #111111;
	}

	@media screen and (max-width:1500px) {
		.logoimg {
			width: 30px;
			height: 30px;
		}

		.nav-item {
			font-size: 12px;
		}

		.nav-item-current {
			font-size: 12px;
		}

		.iconfont {
			font-size: 16px;
		}
	}
</style>
