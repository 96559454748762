<template>
	<div class="index-container" ref="container" :style="'height:'+height+'px'">
		<customNav />
		<swiper class="swiper" ref='mySwiper' :options="swiperOption" @slideChange="handleSlide">

			<swiper-slide v-for="(item,index) in bannerlist" :key="index" :style="'height:' +height+'px'">
				<div class="item-content" :style="'height:' +height+'px'">
					<img :src="item.src" :style="'height:' +height+'px'" class="img">
					<template v-if="index == 0">
						<div class="btn" @click="handleClickTypeS">
							<span>了解更多</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="index == 1">
						<div class="btn1">
							<span>了解更多</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="index == 2">
						<div class="btn2">
							<span>了解更多</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="index == 3">
						<div class="btn3">
							<span>了解更多</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
					<template v-if="index == 4">
						<div class="btn4">
							<span>了解更多</span>
							<span class="iconfont icon-right"></span>
						</div>
					</template>
				</div>
			</swiper-slide>

			<swiper-slide>
				<bottom-bar></bottom-bar>
			</swiper-slide>
		</swiper>

		<div v-if="activeIndex != 4" class="mouse-box">
			<img src="../assets/image/mouse.gif" @click="handleMouseClick">
		</div>
	</div>
</template>


<script>
	import customNav from '@/components/custom-nav.vue'
	import bottomBar from '@/components/bottomBar.vue';
	import {
		debounce
	} from "@/utils/util.js"
	export default {
		name: 'index',
		data() {
			return {
				swiperOption: {
					direction: 'vertical',
					setWrapperSize: true,
					spaceBetween: 0,
					mousewheel: true,
					autoHeight: true,
					slidesPerView: 'auto',
					observer: true,
					 observeParents: true,
				},
				isShow: true,
				height: null,
				num: 0,
				bannerlist: [{
						src: require('@/assets/image/shouye.jpg')
					},
					{
						src: require('@/assets/image/swiper-item02.png')
					},
					{
						src: require('@/assets/image/swiper-item03.png')
					},
					{
						src: require('@/assets/image/swiper-item04.png')
					},
					{
						src: require('@/assets/image/swiper-item05.png')
					}
				],
				activeIndex: 0,
			}
		},
		
		mounted() {
			console.log('hahha')
			this.height = document.documentElement.clientHeight
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper
			}
		},
		components: {
			bottomBar,
			customNav,
		},

		watch: {
			num: function(newValue, oldValue) {
				if (newValue > 3) {
					this.isShow = false
				} else {
					this.isShow = true
				}
			}
		},
		methods: {
			
			handleClick() {
				this.num++;
				this.handleScroll(this.num)
			},
			handleClickTypeS() {
				this.$router.push('/goodS')
			},
			handleSlide(e) {
				this.activeIndex = this.swiper.activeIndex
			},
			handleMouseClick() {
				this.swiper.slideNext()
			}
		}
	}
</script>

<style lang="scss">
	.swiper {
		.swiper-slide:last-child {
			height: 320px !important;
		}

		@media screen and (max-width:1700px) {
			.swiper-slide:last-child {
				height: 290px !important;
			}
		}

		@media screen and (max-width:1600px) {
			.swiper-slide:last-child {
				height: 290px !important;
			}
		}
		@media screen and (max-width:1500px) {
			.swiper-slide:last-child {
				height: 240px !important;
			}
		}
	}
</style>


<style lang="scss" scoped="scoped">
	.item-content {
		width: 100%;
		height: 100%;
		// background-color: red;
		position: relative;

		.img {
			width: 100%;
		}
	}

	.mouse-box {
		position: fixed;
		bottom: 10px;
		right: 0;
		left: 0;
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 30px;
		}
	}

	.infinite-list-item {
		position: relative;

		img {
			height: 100%;
			width: 100%;
		}

	}

	.btn {
		position: absolute;
		right: 22%;
		top: 45%;
		background: #ffffff;
		border-radius: 23px;
		padding: 10px 30px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;

		span {
			font-size: 16px;
			color: #000000;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	.btn1 {
		position: absolute;
		left: 22.5%;
		top: 45%;
		background: #fff;
		border-radius: 23px;
		padding: 10px 30px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;


		span {
			font-size: 16px;
			color: #111111;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	.btn2 {
		position: absolute;
		right: 21%;
		top: 43%;
		background: #fff;
		border-radius: 23px;
		padding: 10px 30px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;


		span {
			font-size: 16px;
			color: #111111;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	.btn3 {
		position: absolute;
		left: 19%;
		top: 55%;
		background: #fff;
		border-radius: 23px;
		padding: 10px 30px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;


		span {
			font-size: 16px;
			color: #111111;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	.btn4 {
		position: absolute;
		right: 18%;
		top: 53%;
		background: #111111;
		border-radius: 23px;
		padding: 10px 30px;
		z-index: 2;
		display: flex;
		transition-property: background-color;
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;


		span {
			font-size: 16px;
			color: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.iconfont {
			font-size: 20px;
		}

		&:hover {
			background: #4AB17D;

			span {
				color: #FFF;
			}
		}
	}

	@media screen and (max-width:1500px) {
		.mouse {

			img {
				width: 30px;
				;
			}
		}

		.btn {
			padding: 10px 30px;

			span {
				font-size: 14px;
			}

			.iconfont {
				font-size: 16px;
			}

			&:hover {
				background: #4AB17D;

				span {
					color: #FFF;
				}
			}
		}

		.btn1 {
			padding: 10px 30px;

			span {
				font-size: 12px;
			}

			.iconfont {
				font-size: 16px;
			}
		}

		.btn2 {
			padding: 10px 40px;

			span {
				font-size: 14px;
			}

			.iconfont {
				font-size: 16px;
			}
		}

		.btn3 {
			padding: 10px 30px;

			span {
				font-size: 14px;
			}

			.iconfont {
				font-size: 16px;
			}
		}

		.btn4 {
			padding: 10px 30px;

			span {
				font-size: 14px;
			}

			.iconfont {
				font-size: 16px;
			}
		}
	}
</style>
